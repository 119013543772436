import { useState } from 'react';
import {
    autoUpdate,
    flip,
    offset,
    Placement,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useHover,
    useInteractions,
    useRole,
} from '@floating-ui/react';

type UseFloatingDefaultProps = {
    placement?: Placement;
    enableClick?: boolean;
    enableHover?: boolean;
    fallbackPlacements?: Placement[];
};

export default function useFloatingDefault({
    placement = 'bottom-start',
    enableClick,
    enableHover = false,
    fallbackPlacements,
}: UseFloatingDefaultProps = {}) {
    const [isOpen, setIsOpen] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement,
        middleware: [offset(0), flip({ fallbackPlacements }), shift()],
        whileElementsMounted: autoUpdate,
    });

    const click = useClick(context, { enabled: enableClick });
    const hover = useHover(context, {
        move: false,
        enabled: isOpen ? false : enableHover,
    });
    const dismiss = useDismiss(context);
    const role = useRole(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        hover,
        dismiss,
        role,
    ]);

    return {
        isOpen,
        setIsOpen,
        refs,
        floatingStyles,
        getReferenceProps,
        getFloatingProps,
    };
}
