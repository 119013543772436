import React from 'react';
import { FloatingPortal } from '@floating-ui/react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import cx from 'classnames';

import useFloatingDefault from '../utils/useFloatingDefault';
import useResponsive from '../hooks/useResponsive';

type HotelSelectProps = {
    setBookingEngineIdState: (pk: string) => void;
    selectedBookingEngineId: string;
};

type HotelsByRegion = {
    pk: string;
    name: string;
    hotels: Hotel[];
};

type Hotel = {
    pk: string;
    name: string;
    booking_engine_id: number;
    city: string;
    price_id: number;
};

function findHotelNameByBookingEngineId(
    hotelsByRegionData: HotelsByRegion[],
    selectedBookingEngineId: string
) {
    let hotelName = null;

    hotelsByRegionData.some((region) => {
        const hotel = region.hotels.find(
            (h) => h.booking_engine_id === parseInt(selectedBookingEngineId, 10)
        );
        if (hotel) {
            hotelName = hotel.name.replace(/<\/?span>/g, '');
            return true;
        }
        return false;
    });

    return hotelName;
}

export default function HotelSelect({
    setBookingEngineIdState,
    selectedBookingEngineId,
}: HotelSelectProps) {
    const {
        isOpen,
        setIsOpen,
        refs,
        floatingStyles,
        getFloatingProps,
        getReferenceProps,
    } = useFloatingDefault({ placement: 'top-start' });
    const { t } = useTranslation();
    const { data: hotelsByRegionData, isLoading } =
        useSWR<HotelsByRegion[]>('hotel/by-region');
    const responsive = useResponsive();

    if (isLoading || !hotelsByRegionData)
        return <div className={'loaderanimation'} />;

    return (
        <>
            <div
                className={cx('c-booking-mask__input c-booking-mask__column', {
                    'c-booking-mask__input--active': isOpen,
                })}
                ref={refs.setReference}
                {...getReferenceProps()}
            >
                <div className={'c-booking-mask__input-inner'}>
                    {selectedBookingEngineId
                        ? findHotelNameByBookingEngineId(
                              hotelsByRegionData,
                              selectedBookingEngineId
                          )
                        : t('Kies een hotel')}
                </div>
            </div>
            {isOpen && (
                <FloatingPortal>
                    <div
                        className={'c-booking-mask__selector-container'}
                        ref={refs.setFloating}
                        style={
                            responsive !== 'mobile' ? floatingStyles : undefined
                        }
                        {...getFloatingProps()}
                    >
                        <div className={'c-booking-mask-popup'}>
                            <div className={' c-booking-mask-popup__header'}>
                                <h3>{t('Kies een hotel')}</h3>
                                <button
                                    className="c-booking-mask-popup__close-button"
                                    type="button"
                                    onClick={() => setIsOpen(false)}
                                >
                                    <span className="hidden">{t('Close')}</span>
                                </button>
                            </div>
                            <div
                                className={
                                    'c-hotel-selector__regions c-booking-mask-popup__body'
                                }
                            >
                                {hotelsByRegionData.map((region) => {
                                    return (
                                        <div key={region.pk}>
                                            <span>{region.name}</span>
                                            <ul
                                                className={
                                                    'c-hotel-selector__hotel-list'
                                                }
                                            >
                                                {region.hotels.map((hotel) => {
                                                    const hotelName =
                                                        hotel.name.replace(
                                                            /<\/?span>/g,
                                                            ''
                                                        );
                                                    return (
                                                        <li key={hotel.pk}>
                                                            <input
                                                                className={
                                                                    'c-hotel-selector__input'
                                                                }
                                                                id={
                                                                    'booking-mask-hotel-' +
                                                                    hotel.pk.toString()
                                                                }
                                                                type={'radio'}
                                                                value={
                                                                    hotel.booking_engine_id
                                                                }
                                                                checked={
                                                                    selectedBookingEngineId ===
                                                                    hotel.booking_engine_id.toString()
                                                                }
                                                                onChange={(e) =>
                                                                    setBookingEngineIdState(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                            <label
                                                                className={
                                                                    'c-hotel-selector__label'
                                                                }
                                                                htmlFor={
                                                                    'booking-mask-hotel-' +
                                                                    hotel.pk.toString()
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        'l-hotel-name-container'
                                                                    }
                                                                >
                                                                    <div>
                                                                        {
                                                                            hotelName
                                                                        }
                                                                    </div>
                                                                    <div className="c-hotel-selector__city">
                                                                        {
                                                                            hotel.city
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    );
                                })}
                            </div>

                            {responsive === 'mobile' && (
                                <div className={'c-booking-mask-popup__footer'}>
                                    <button
                                        className={
                                            'c-booking-mask-popup__confirm c-button c-button--primary'
                                        }
                                        type="button"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        {t('Selecteer')}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </FloatingPortal>
            )}
        </>
    );
}
