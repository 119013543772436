import React, { useEffect, useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/counter.css';
import ReactPlayer from 'react-player';

type LightBoxProps = {
    el?: HTMLElement;
    clickHandlerId?: string;
};

type Slide = ImageSlide | VideoSlide;

type ImageSlide = {
    srcSet?: {
        src: string;
        width: number;
    }[];
    description?: string;
};

type VideoSlide = {
    type: 'video';
    src: string;
    description?: string;
};

// Overriding lightbox slide types so it support custom slide video.
declare module 'yet-another-react-lightbox' {
    export interface LightBoxVideoSlide extends GenericSlide {
        type: 'video';
        src: string;
        description?: string;
    }

    interface SlideTypes {
        video: LightBoxVideoSlide;
    }
}

export default function LightBox({
    el = undefined,
    clickHandlerId = undefined,
}: LightBoxProps) {
    const [index, setIndex] = React.useState(-1);
    const [slides, setSlides] = useState<Slide[]>([]);

    useEffect(() => {
        if (!el?.parentElement) return;
        const figureElements = el.parentElement.getElementsByTagName('figure');
        const pictureElements = [...figureElements].map((figureElement) => {
            return {
                figure: figureElement,
                picture: figureElement.getElementsByTagName('picture')[0],
                caption: figureElement.getElementsByClassName(
                    'js-lightbox-caption'
                )?.[0]?.innerHTML,
                videoUrl: figureElement.dataset?.videoUrl,
            };
        });

        const tempSlides: Slide[] = [];
        [...pictureElements].forEach((pictureElement, i) => {
            pictureElement.figure.addEventListener('click', (e) => {
                e.preventDefault();
                setIndex(i);
            });

            const imageElement =
                pictureElement.picture?.getElementsByTagName('img')[0];
            const srcset = imageElement?.srcset.split(', ').map((s) => {
                const splitString = s.split(' ');
                return {
                    src: splitString[0] ? splitString[0] : '',
                    width: splitString[1]
                        ? parseInt(splitString[1].replace('w', ''), 10)
                        : 0,
                };
            });
            if (pictureElement.videoUrl) {
                tempSlides.push({
                    src: pictureElement.videoUrl,
                    type: 'video',
                    description: pictureElement.caption,
                });
            } else {
                tempSlides.push({
                    description: pictureElement.caption,
                    srcSet: srcset,
                });
            }
        });
        setSlides(tempSlides);
    }, [el]);

    useEffect(() => {
        if (clickHandlerId) {
            const clickEl = document.getElementById(clickHandlerId);
            if (clickEl) {
                clickEl.addEventListener('click', (e) => {
                    e.preventDefault();
                    setIndex(0);
                });
            }
        }
    }, [clickHandlerId]);

    return (
        <Lightbox
            plugins={[Captions, Counter]}
            captions={{ descriptionTextAlign: 'center' }}
            index={index}
            /* @ts-ignore */
            slides={slides}
            open={index >= 0}
            close={() => setIndex(-1)}
            render={{
                slide: ({ slide }) =>
                    slide.type === 'video' ? (
                        <ReactPlayer
                            width="100%"
                            height="100%"
                            url={slide.src}
                            controls={true}
                        />
                    ) : null,
            }}
        />
    );
}
