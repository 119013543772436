import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const htmlElement = document.querySelector('html');

// The await is necessary to let i18n work in the vanilla js files. This is because it has to wait to initialize.
await i18n
    .use(Backend)
    // connect with React
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,

        lng: htmlElement?.lang || 'nl',
        fallbackLng: 'nl',

        nsSeparator: false,
        keySeparator: false,

        returnEmptyString: false, // use the key as the default value

        interpolation: {
            escapeValue: false, // react already safe from xss
        },

        backend: {
            loadPath: '/static/locale/{{lng}}/{{ns}}.json', // path where the XHR backend will fetch the files from
        },
    });

export default i18n;
