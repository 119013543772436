import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios, { AxiosError } from 'axios';
import useModal from '../hooks/useModal';
import GiftCardsConfirmation from './GiftCardsConfirmation';
import { ResponseData } from '../types/types';
import GiftCardInputs from './GiftCardInputs';

type GiftCardsFormProps = {
    id?: string;
    defaultPrice?: string;
    processingCosts?: string;
};

type Errors = {
    [key: string]: string[];
};

export default function GiftCardsForm({
    id = undefined,
    defaultPrice = 'false',
    processingCosts = undefined,
}: GiftCardsFormProps) {
    const { t } = useTranslation();

    const [formState, setFormState] = useState([
        {
            id: 0,
            gift_card_type: id ? parseInt(id, 10) : -1,
            amount: 1,
            price: defaultPrice !== 'false' ? parseInt(defaultPrice, 10) : 25,
        },
    ]);
    const [errors, setErrors] = useState<Errors | undefined>(undefined);
    const [responseData, setResponseData] = useState<ResponseData | undefined>(
        undefined
    );
    const dialogRef = useRef<HTMLDialogElement | null>(null);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const { openDialog, closeDialog } = useModal(dialogRef, modalRef);

    const cleanupMapRef = useRef(
        new WeakMap<HTMLFormElement, (() => void)[]>()
    );

    useEffect(() => {
        const cleanupMap = cleanupMapRef.current;
        const giftCardForms = document.getElementsByClassName(
            'js-giftcard-form'
        ) as HTMLCollectionOf<HTMLFormElement>;

        const handleValidate = async (e: Event, form: HTMLFormElement) => {
            e.preventDefault();
            const formData = new FormData(e.target as HTMLFormElement);
            const sendToReceiverCheckbox = form.querySelector(
                '.js-send-to-receiver-checkbox'
            ) as HTMLInputElement;
            const includeHolidayGiftCardCoverCheckbox = form.querySelector(
                '.js-include-holiday-gift-card-cover-checkbox'
            ) as HTMLInputElement;

            let submitData = {
                giftcards: formState,
                send_to_receiver: sendToReceiverCheckbox?.checked,
                include_holiday_gift_card_cover:
                    includeHolidayGiftCardCoverCheckbox?.checked,
            };
            Array.from(formData.entries()).forEach(([key, value]) => {
                if (value && value !== '') {
                    submitData = { ...submitData, [key]: value };
                }
            });

            try {
                const response = await axios.post('/order/?validate=true', {
                    ...submitData,
                });

                if (!response.data.validated) {
                    const validationError = {
                        general: t('Validatie mislukt'),
                    };
                    throw new Error(JSON.stringify(validationError));
                }

                setResponseData(response.data);
                openDialog();
                setErrors(undefined);
            } catch (error) {
                const catchErrors = error as AxiosError;
                if (catchErrors?.response?.data) {
                    setErrors(catchErrors.response.data as Errors);
                }

                if (catchErrors?.message) {
                    setErrors(JSON.parse(catchErrors.message) as Errors);
                }
            }
        };

        const htmlElement = document.querySelector('html');

        const changeScrollBehavior = (form: HTMLFormElement) => {
            const htmlEl = document.querySelector('html');
            if (htmlEl) {
                htmlEl.style.scrollBehavior = 'initial';
                form.reportValidity();
                htmlEl.style.scrollBehavior = 'smooth';
            }
        };

        if (htmlElement) {
            [...giftCardForms].forEach((form) => {
                const submitHandler = (e: Event) => handleValidate(e, form);
                const cleanupFunctions: (() => void)[] = [];
                form.addEventListener('submit', submitHandler);
                cleanupFunctions.push(() =>
                    form.removeEventListener('submit', submitHandler)
                );

                const submitButtons = form.getElementsByClassName(
                    'js-form-submit-button'
                );

                [...submitButtons].forEach((submitButton) => {
                    const clickHandler = () => {
                        changeScrollBehavior(form);
                    };
                    submitButton.addEventListener('click', clickHandler);
                    cleanupFunctions.push(() =>
                        submitButton.removeEventListener('click', clickHandler)
                    );
                });

                cleanupMap.set(form, cleanupFunctions);
            });
        }

        [...giftCardForms].forEach((form) => {
            const sendToReceiverCheckbox = form.querySelector(
                '.js-send-to-receiver-checkbox'
            ) as HTMLInputElement;

            sendToReceiverCheckbox.addEventListener('change', (e) => {
                const target = e.target as HTMLInputElement;
                const inputIds = [
                    '#id_receiver_gender',
                    '#id_receiver_initials',
                    '#id_receiver_last_name',
                    '#id_receiver_street',
                    '#id_receiver_house_number',
                    '#id_receiver_postal_code',
                    '#id_receiver_city',
                ];

                inputIds.forEach((inputId) => {
                    const inputElement = form.querySelector(inputId);
                    if (inputElement) {
                        if (target.checked) {
                            inputElement.setAttribute('required', 'true');
                        } else {
                            inputElement.removeAttribute('required');
                        }
                    }
                });
            });
        });

        return () => {
            [...giftCardForms].forEach((form) => {
                const cleanupFunctions = cleanupMap.get(form);
                if (cleanupFunctions) {
                    cleanupFunctions.forEach((cleanup) => cleanup());
                    cleanupMap.delete(form);
                }
            });
        };
    }, [formState, t, openDialog, cleanupMapRef]);

    useEffect(() => {
        const errorDiv = document.querySelector('.js-errors');

        if (errorDiv) {
            errorDiv.innerHTML = '';

            if (errors) {
                (Object.entries(errors) as [string, string[]][]).forEach(
                    ([key, errorMessages]: [string, string[]]) => {
                        errorMessages.forEach(
                            (errorMessage: string | { string: string[] }) => {
                                if (typeof errorMessage !== 'string') {
                                    (
                                        Object.entries(errorMessage) as [
                                            string,
                                            string[],
                                        ][]
                                    ).forEach(
                                        ([nestedKey, nestedErrorMessages]: [
                                            string,
                                            string[],
                                        ]) => {
                                            nestedErrorMessages.forEach(
                                                (
                                                    nestedErrorMessage:
                                                        | string
                                                        | { string: string[] }
                                                ) => {
                                                    const errorItem =
                                                        document.createElement(
                                                            'div'
                                                        );
                                                    errorItem.textContent = `${nestedKey}: ${nestedErrorMessage}`;
                                                    errorDiv.appendChild(
                                                        errorItem
                                                    );
                                                }
                                            );
                                        }
                                    );
                                } else {
                                    const errorItem =
                                        document.createElement('div');
                                    errorItem.textContent = `${key}: ${errorMessage}`;
                                    errorDiv.appendChild(errorItem);
                                }
                            }
                        );
                    }
                );
            }
        }
    }, [errors]);

    const totalPrice = formState
        .reduce((total, giftCard) => {
            return total + giftCard.amount * giftCard.price;
        }, 0)
        .toFixed(2);

    if (!id) {
        return (
            <>
                {t('Er is iets misgegaan, javascript kan niet worden geladen.')}
            </>
        );
    }

    return (
        <>
            <GiftCardsConfirmation
                dialogRef={dialogRef}
                modalRef={modalRef}
                closeDialog={() => closeDialog()}
                responseData={responseData}
                processingCosts={processingCosts}
            />
            <input
                type="hidden"
                name="giftcards-TOTAL_FORMS"
                value={formState.length}
                id="id_giftcards-TOTAL_FORMS"
            />
            {formState.map((giftCard) => {
                return (
                    <GiftCardInputs
                        key={giftCard.id}
                        giftCard={giftCard}
                        id={id}
                        defaultPrice={defaultPrice}
                        formState={formState}
                        setFormState={setFormState}
                    />
                );
            })}
            {!Number.isNaN(+totalPrice) && (
                <div className={'c-giftcard-widget__order-row'}>
                    <span className={'c-giftcard-widget__total-price'}>
                        €&nbsp;{totalPrice}
                    </span>
                </div>
            )}
            {defaultPrice === 'false' && (
                <button
                    type="button"
                    className={'t-link'}
                    onClick={() =>
                        setFormState((prevState) => {
                            const lastId =
                                prevState[prevState.length - 1]?.id ?? 0;
                            const idx = lastId + 1;

                            return [
                                ...prevState,
                                {
                                    id: idx,
                                    amount: 1,
                                    price: 25,
                                    gift_card_type: parseInt(id, 10),
                                },
                            ];
                        })
                    }
                >
                    {t('+ Add another gift card')}
                </button>
            )}
        </>
    );
}
