import React, { useState, useEffect, useRef } from 'react';
import type { Marker } from '@googlemaps/markerclusterer';
import cx from 'classnames'; // Used for easy addition of classes. Somewhat like jquery's $foo.addClass()
import parse from 'html-react-parser';

import {
    APIProvider,
    Map,
    useMap,
    AdvancedMarker,
} from '@vis.gl/react-google-maps';

import {
    MarkerClusterer,
    MarkerUtils,
    GridAlgorithm,
} from '@googlemaps/markerclusterer';
import { useTranslation } from 'react-i18next';

const apiKey = 'AIzaSyBqCxHGS-in4vr_MfbA1oWM3ejxemCkqd4';

type LocationObject = {
    lat: number;
    lng: number;
    hotelId: number | boolean;
    picture: any;
    title: string | undefined;
    city: string | undefined;
    citySlug: string | undefined;
    url: string | undefined;
    price: string | undefined;
    rating: string | undefined;
};

type LocationMarkerProps = {
    locations: LocationObject[];
    setHotel: (pk: number | boolean) => void;
    hotelId: number | boolean;
};

function getHTML(html: string) {
    return {
        __html: html,
    };
}

function LocationMarkers({
    locations,
    setHotel,
    hotelId,
}: LocationMarkerProps) {
    const googleMap = useMap();
    const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
    const clusterer = useRef<MarkerClusterer | null>(null);

    // Initialize MarkerClusterer, if the map has changed
    useEffect(() => {
        const algorithm = new GridAlgorithm({
            gridSize: 50,
            maxZoom: 14,
        });

        if (!googleMap) return;
        if (!clusterer.current) {
            clusterer.current = new MarkerClusterer({
                map: googleMap,
                algorithm,
                renderer: {
                    render: ({ count, position }, _, map) => {
                        // change color if this cluster has more markers than the mean cluster
                        const color = '#FF6A13';
                        // create svg literal with fill color
                        const svg = `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="60" height="60"> <circle cx="120" cy="120" opacity="1" r="70" /> <!--<circle cx="120" cy="120" opacity=".3" r="90" />--> <!--<circle cx="120" cy="120" opacity=".2" r="110" />--> <text x="50%" y="50%" style="fill:#fff" text-anchor="middle" font-size="50" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${count}</text> </svg>`;
                        const title = `Cluster of ${count} markers`;
                        const zIndex =
                            Number(google.maps.Marker.MAX_ZINDEX) + count;

                        if (MarkerUtils.isAdvancedMarkerAvailable(map)) {
                            // create cluster SVG element
                            const parser = new DOMParser();
                            const svgEl = parser.parseFromString(
                                svg,
                                'image/svg+xml'
                            ).documentElement;
                            svgEl.setAttribute('transform', 'translate(0 25)');
                            const clusterOptions = {
                                map,
                                position,
                                zIndex,
                                title,
                                content: svgEl,
                            };
                            return new google.maps.marker.AdvancedMarkerElement(
                                clusterOptions
                            );
                        }
                        const clusterOptions = {
                            position,
                            zIndex,
                            title,
                            icon: {
                                url: `data:image/svg+xml;base64,${btoa(svg)}`,
                                anchor: new google.maps.Point(25, 25),
                            },
                        };
                        return new google.maps.Marker(clusterOptions);
                    },
                },
            });
        }
    }, [googleMap]);

    useEffect(() => {
        if (clusterer.current) {
            /* @ts-ignore */
            if ((clusterer.current?.markers?.length || 0) > 0) {
                clusterer.current?.clearMarkers(true);
            }
            clusterer.current?.addMarkers(Object.values(markers), true);
        }
    }, [markers, clusterer]);

    const setMarkerRef = (marker: Marker | null, key: string) => {
        if (marker && markers[key]) return;
        if (!marker && !markers[key]) return;

        setMarkers((prev) => {
            if (marker) {
                return { ...prev, [key]: marker };
            }

            const newMarkers = { ...prev };
            delete newMarkers[key];
            return newMarkers;
        });
    };

    return (
        <>
            {locations.map((location) => {
                // console.log('::::', typeof window.google.maps.Size);
                if (typeof window.google.maps.Size !== 'function') {
                    return null;
                }
                const Img =
                    hotelId &&
                    location.hotelId &&
                    hotelId === location.hotelId ? (
                        <img
                            alt={'marker for google maps'}
                            src="/static/images/marker-black.svg"
                            width={60}
                        />
                    ) : (
                        <img
                            alt={'marker for google maps'}
                            src="/static/images/marker.svg"
                            width={45}
                        />
                    );
                const key = 'key-' + location.lat + '-' + location.lng;
                return (
                    <AdvancedMarker
                        zIndex={1}
                        key={key}
                        position={{
                            lat: location.lat,
                            lng: location.lng,
                        }}
                        onClick={() =>
                            locations?.length > 1
                                ? setHotel(location.hotelId)
                                : null
                        }
                        ref={(marker) => setMarkerRef(marker, key)}
                    >
                        {Img}
                    </AdvancedMarker>
                );
            })}
        </>
    );
}

type GoogleMapsProps = {
    locations: LocationObject[];
    setHotel: (pk: number | boolean) => void;
    hotelId: number | boolean;
};

function GoogleMaps({ locations, setHotel, hotelId }: GoogleMapsProps) {
    const calculatedCenter =
        locations?.length === 1
            ? { lat: locations[0]?.lat, lng: locations[0]?.lng }
            : { lat: 51.6459, lng: 9.0124 };
    const [loaded, setLoaded] = useState(false);
    // const [bounds, setBounds] = useState(new window.google.maps.LatLngBounds())
    const googleMap = useMap();

    useEffect(() => {
        if (loaded && locations.length > 0) {
            const newBounds = new window.google.maps.LatLngBounds();
            locations.forEach((location) => {
                newBounds.extend({
                    lat: location.lat,
                    lng: location.lng,
                });
            });

            // Padding is used to move the screen a bit off-center as it is visually more inclusive for the Netherlands
            const padding: google.maps.Padding = {
                top: 150,
                right: 0,
                bottom: 0,
                left: 0,
            };
            googleMap?.fitBounds(newBounds, padding);
            // setBounds(newBounds);
        }
    }, [locations, googleMap, loaded]);

    useEffect(() => {
        if (hotelId) {
            // todo: We actually want to find all visible markers in the current view (getBounds), and then use those
            // todo: items to center on the selected marker. So we want to center as much as possible, but prevent the visible
            // todo: markers from scrolling outside
            // googleMap?.getBounds()
            const location = locations.find(
                (o) => hotelId && o.hotelId && o.hotelId === hotelId
            );
            if (location?.lat && location?.lng) {
                googleMap?.panTo({
                    lat: location.lat,
                    lng: location.lng,
                });
            }
        }
    }, [googleMap, hotelId, locations]);

    return (
        <Map
            mapId={
                locations?.length === 1
                    ? 'fe447b78e3279e53'
                    : '6636b4b2458f09db'
            }
            mapTypeControl={false}
            fullscreenControl={false}
            zoomControl={true}
            onTilesLoaded={() => {
                setLoaded(true);
            }}
            // // panControl = {false}
            // zoomControl={true}
            scaleControl={false}
            clickableIcons={false}
            scrollwheel={false}
            streetViewControl={false}
            maxZoom={locations?.length === 1 ? 17 : 14}
            // mapTypeId={google.maps.MapTypeId.ROADMAP}
            // styles={styles}
            defaultZoom={locations?.length === 1 ? 17 : 7}
            defaultCenter={
                calculatedCenter.lat && calculatedCenter.lng
                    ? {
                          lat: calculatedCenter.lat,
                          lng: calculatedCenter.lng,
                      }
                    : undefined
            }
        >
            <LocationMarkers
                locations={locations}
                setHotel={setHotel}
                hotelId={hotelId}
            />
        </Map>
    );
}

type GoogleMapsContainerProps = {
    setHotel: (pk: number | boolean) => void;
    hotelId: number | boolean;
    locations: LocationObject[];
};

export function GoogleMapsContainer({
    setHotel,
    hotelId,
    locations,
}: GoogleMapsContainerProps) {
    const [apiLoaded, setApiLoaded] = useState(false);

    return (
        <div
            id="map"
            className={'c-google-maps-cluster__map c-columned-content__item'}
        >
            <APIProvider
                apiKey={apiKey}
                onLoad={() => {
                    setApiLoaded(true);
                }}
            >
                {apiLoaded && (
                    <GoogleMaps
                        locations={locations}
                        setHotel={setHotel}
                        hotelId={hotelId}
                    />
                )}
            </APIProvider>
        </div>
    );
}

type HotelSidebarProps = {
    hotelId: number | boolean;
    locations: LocationObject[];
};

function HotelSidebar({ hotelId, locations }: HotelSidebarProps) {
    const { t } = useTranslation();
    const location: LocationObject | boolean =
        locations.find((o) => hotelId && o.hotelId === hotelId) || false;

    if (location) {
        return (
            <div
                className={cx(
                    'c-google-maps-cluster__hotel c-columned-content__item c-columned-content__item--compact'
                )}
            >
                {location?.picture && parse(location.picture.outerHTML)}
                {location.rating && (
                    <div className="c-hotel-rating">
                        {location.rating}
                        <span className="c-hotel-rating__sub">
                            {t('rating')}
                        </span>
                    </div>
                )}
                <div className="c-columned-content__body">
                    <div className="c-columned-content__description">
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <a href={location.url} className="href">
                            <h3
                                className={
                                    'c-columned-content__heading t-heading-arrowed'
                                }
                                dangerouslySetInnerHTML={getHTML(
                                    location.title || ''
                                )}
                            />
                        </a>
                        <span className={'c-columned-content__subheading'}>
                            {location.city}
                        </span>
                    </div>
                    {location.price && (
                        <span className={'c-columned-content__payoff'}>
                            {t('Vanaf')} &euro;{' '}
                            {parseFloat(location.price).toFixed(0)}{' '}
                            {t('per nacht')}
                        </span>
                    )}
                    <a
                        href={location.url}
                        className="t-link c-columned-content__payoff"
                    >
                        {t('Meer over dit hotel')}
                    </a>
                </div>
            </div>
        );
    }
}

export default function GoogleMapsCluster() {
    const [hotel, setHotel] = useState<number | boolean>(false);
    const [locations, setLocations] = useState<LocationObject[]>([]);

    useEffect(() => {
        const newLocations: LocationObject[] = [];
        const mapItems = document.querySelectorAll(
            '.map-items'
        ) as NodeListOf<HTMLElement>;

        mapItems.forEach((item) => {
            if (!item.dataset.lat || !item.dataset.long) return;
            const lat = parseFloat(item.dataset?.lat?.replace(',', '.'));
            const lng = parseFloat(item.dataset?.long?.replace(',', '.'));
            const hotelLocation: LocationObject = {
                lat,
                lng,
                hotelId: item.dataset?.id
                    ? parseInt(item.dataset?.id, 10)
                    : false,
                picture: item.querySelector('picture'),
                title: item.dataset?.name,
                city: item.dataset?.city,
                citySlug: item.dataset?.citySlug,
                url: item.dataset?.url,
                price: item.dataset?.price,
                rating: item.dataset?.rating
                    ? parseFloat(item.dataset.rating).toFixed(1)
                    : undefined,
            };
            newLocations.push(hotelLocation);
        });
        setLocations(newLocations);
    }, []);

    return (
        <div
            className={cx('c-columned-content', {
                'c-columned-content--two-one': !!hotel,
            })}
        >
            <GoogleMapsContainer
                setHotel={(pk) => setHotel(pk)}
                hotelId={hotel}
                locations={locations}
            />
            <HotelSidebar hotelId={hotel} locations={locations} />
        </div>
    );
}
