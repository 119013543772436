export default function Modal() {
    const closeButtons = document.getElementsByClassName('js-close-modal');
    if (closeButtons && closeButtons.length > 0) {
        [...closeButtons].forEach((button) => {
            const parentDialog = button.closest('dialog');
            if (parentDialog) {
                button.addEventListener('click', () => {
                    parentDialog.close();
                });
            }
        });
    }

    const modals = document.getElementsByClassName(
        'js-modal'
    ) as HTMLCollectionOf<HTMLDialogElement>;

    if (modals && modals.length > 0) {
        [...modals].forEach((modal) => {
            document.onclick = (e) => {
                if (e.target === modal) {
                    modal.close();
                }
            };
        });
    }
}
