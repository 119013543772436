import Cookies from 'js-cookie';

export default function PromotionPopup() {
    if (Cookies.get('promotionPopup') !== 'true') {
        const jsPromotionPopup =
            document.getElementsByClassName('js-promotion-popup');
        if (
            jsPromotionPopup.length > 0 &&
            (window.location.href.includes('buitenkansen') ||
                window.location.href.includes('special-offers') ||
                window.location.href.includes('sonderangebote'))
        ) {
            const promotionModal = document.getElementById(
                'promotion-modal'
            ) as HTMLDialogElement;
            if (promotionModal) {
                promotionModal.showModal();

                const subscribed = document.getElementById('a-subscribed');

                if (subscribed) {
                    subscribed.onclick = () => {
                        Cookies.set('promotionPopup', 'true', {
                            expires: 365,
                        });
                        promotionModal.close();
                    };
                }
            }
        }
    }
}
