import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Cookies from 'js-cookie';

export default function NewsletterForm() {
    const [formData, setFormData] = useState({});
    const { t } = useTranslation();

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        await axios.post('/newsletter/default/', {
            ...formData,
            page_source: currentPageSlug,
        });

        Cookies.set('promotionPopup', 'true', {
            expires: 365,
        });

        const promotionModal = document.getElementById(
            'promotion-modal'
        ) as HTMLDialogElement;
        if (promotionModal) {
            promotionModal.close();
        }
    }

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <fieldset>
                <legend className="hidden">{t('Gegevens')}</legend>
                <input name="page_source" type="hidden" value="" />
                <div className="form-row">
                    <label htmlFor="email-newsletter">
                        {t('Vul uw e-mailadres in')}
                    </label>
                    <input
                        id="email-newsletter"
                        type="email"
                        name="email"
                        placeholder={t('voorbeeld@domein.nl')}
                        onChange={(e) =>
                            setFormData({ ...formData, email: e.target.value })
                        }
                    />
                </div>
                <div className="form-row form-row--right">
                    <button
                        className="c-button c-button--primary"
                        type="submit"
                    >
                        {t('Buitenkansen bekijken')}
                    </button>
                </div>
            </fieldset>
        </form>
    );
}
