import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import HotelSelect from './HotelSelect';
import RoomSpecifications from './RoomSpecifications';
import SynxisSubmit from './SynxisSubmit';
import BookingMaskDatePicker from './BookingMaskDatePicker';
import BookingMaskTotalPrice from './BookingMaskTotalPrice';

type BookingMaskProps = {
    bookingEngineId?: string;
    room?: string;
    roomId?: string;
};

export default function BookingMask({
    bookingEngineId = '',
    room = '',
    roomId = '',
}: BookingMaskProps) {
    const [bookingEngineIdState, setBookingEngineIdState] =
        useState(bookingEngineId);
    const [dates, setDates] = useState<[Date?, Date?]>([]);
    const [adults, setAdults] = useState(
        globalConstants.roomConfiguration.adults.default
    );
    const [childrenState, setChildrenState] = useState(
        globalConstants.roomConfiguration.children.default
    );
    const [rooms, setRooms] = useState(
        globalConstants.roomConfiguration.rooms.default
    );

    const { t } = useTranslation();

    return (
        <div className={'c-booking-mask__form'}>
            {!bookingEngineId && (
                <HotelSelect
                    setBookingEngineIdState={setBookingEngineIdState}
                    selectedBookingEngineId={bookingEngineIdState}
                />
            )}

            <BookingMaskDatePicker
                dates={dates}
                bookingEngineId={bookingEngineIdState}
                setDates={setDates}
                roomId={roomId}
            />

            <RoomSpecifications
                adults={adults}
                rooms={rooms}
                childrenState={childrenState}
                setAdults={setAdults}
                setRooms={setRooms}
                setChildrenState={setChildrenState}
            />

            <SynxisSubmit
                text={t('Bekijk beschikbaarheid')}
                adults={adults.toString()}
                rooms={rooms.toString()}
                childrenState={childrenState.toString()}
                bookingEngineId={bookingEngineIdState}
                arriveDate={dates[0]}
                departDate={dates[1]}
                room={room}
            />
            <BookingMaskTotalPrice
                bookingEngineId={bookingEngineIdState}
                adults={adults}
                dates={dates}
                childrenState={childrenState}
                room={room}
                rooms={rooms}
            />
        </div>
    );
}
