import { FloatingPortal } from '@floating-ui/react';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import useFloatingDefault from '../utils/useFloatingDefault';

type GiftCardInputsProps = {
    id: string;
    giftCard: GiftCard;
    defaultPrice: string;
    formState: GiftCard[];
    setFormState: Dispatch<SetStateAction<GiftCard[]>>;
};

type GiftCard = {
    id: number;
    gift_card_type: number;
    price: number;
    amount: number;
};

export default function GiftCardInputs({
    giftCard,
    id,
    defaultPrice,
    formState,
    setFormState,
}: GiftCardInputsProps) {
    const { t } = useTranslation();

    const {
        isOpen,
        refs,
        floatingStyles,
        getFloatingProps,
        getReferenceProps,
    } = useFloatingDefault({ enableHover: true });

    const totalPriceGiftCard = (giftCard.amount * giftCard.price).toFixed(2);

    const [debouncedPrice] = useDebounce(giftCard.price, 1000);
    const [debouncedAmount] = useDebounce(giftCard.amount, 1000);

    useEffect(() => {
        let setFormPrice = debouncedPrice;
        let setFormAmount = debouncedAmount;

        if (!debouncedAmount || debouncedAmount < 1) {
            setFormAmount = 1;
        }

        if (!debouncedPrice || debouncedPrice < 25) {
            setFormPrice = 25;
        }

        if (debouncedPrice > 500) {
            setFormPrice = 500;
        }

        setFormState((prevState) => {
            return prevState.map((gC) => {
                if (giftCard.id !== gC.id) return gC;

                return {
                    ...gC,
                    price: setFormPrice,
                    amount: setFormAmount,
                };
            });
        });
    }, [debouncedPrice, debouncedAmount, giftCard.id, setFormState]);

    return (
        <div className={'c-giftcard-widget__order-row'} key={giftCard.id}>
            <input
                type="hidden"
                name={`giftcards-${giftCard.id}-gift_card_type`}
                value={id}
            />
            <div className={'c-giftcard-widget__input-group'}>
                <label
                    htmlFor={`giftcards-${giftCard.id}-amount`}
                    className={'c-giftcard-widget__label'}
                >
                    {t('Aantal')}
                </label>
                <input
                    type="number"
                    name={`giftcards-${giftCard.id}-amount`}
                    id={`giftcards-${giftCard.id}-amount`}
                    value={giftCard.amount}
                    min={1}
                    onChange={(e) =>
                        setFormState((prevState) => {
                            return prevState.map((gC) => {
                                if (giftCard.id !== gC.id) return gC;

                                return {
                                    ...gC,
                                    amount: e.target.valueAsNumber,
                                };
                            });
                        })
                    }
                />
            </div>
            <div className={'c-giftcard-widget__input-group'}>
                <label
                    htmlFor={`giftcards-${giftCard.id}-price`}
                    className={'c-giftcard-widget__label'}
                >
                    {t('T.w.v.')}
                    {defaultPrice === 'false' && (
                        <>
                            <div
                                className="c-tooltip-button"
                                ref={refs.setReference}
                                {...getReferenceProps()}
                            >
                                <span className="c-tooltip-button__text">
                                    i
                                </span>
                            </div>
                            {isOpen && (
                                <FloatingPortal>
                                    <div
                                        className={
                                            'c-tooltip c-tooltip--active'
                                        }
                                        ref={refs.setFloating}
                                        style={floatingStyles}
                                        {...getFloatingProps()}
                                    >
                                        <span className="c-tooltip__title">
                                            {t('T.w.v.')}
                                        </span>
                                        <div className="c-tooltip__content">
                                            {t(
                                                'Voer uw gewenste cadeaubedrag in. Alle bedragen zijn mogelijk met een minimum van € 25 en een maximum van € 500, afgerond op € 5.'
                                            )}
                                        </div>
                                    </div>
                                </FloatingPortal>
                            )}
                        </>
                    )}
                </label>
                {defaultPrice === 'false' ? (
                    <input
                        type="number"
                        name={`giftcards-${giftCard.id}-price`}
                        id={`giftcards-${giftCard.id}-price`}
                        value={giftCard.price}
                        min={25}
                        max={500}
                        step={5}
                        onChange={(e) => {
                            setFormState((prevState) => {
                                return prevState.map((gC) => {
                                    if (giftCard.id !== gC.id) return gC;

                                    return {
                                        ...gC,
                                        price: e.target.valueAsNumber,
                                    };
                                });
                            });
                        }}
                    />
                ) : (
                    <input
                        type="number"
                        name={`giftcards-${giftCard.id}-price`}
                        id={`giftcards-${giftCard.id}-price`}
                        value={giftCard.price}
                        readOnly
                    />
                )}
            </div>
            <div className={'c-giftcard-widget__input-group'}>
                {!Number.isNaN(+totalPriceGiftCard) && (
                    <>
                        <span className={'label c-giftcard-widget__label'}>
                            {t('Totaal')}
                        </span>
                        <div className={'c-giftcard-widget__price-and-remove'}>
                            <span className={'c-giftcard-widget__price'}>
                                €&nbsp;{totalPriceGiftCard}
                            </span>
                            {formState.length > 1 && (
                                <button
                                    className={'c-giftcard-widget__remove-row'}
                                    type={'button'}
                                    onClick={() =>
                                        setFormState((prevState) =>
                                            prevState.filter(
                                                (gC) => gC.id !== giftCard.id
                                            )
                                        )
                                    }
                                >
                                    <span className={'hidden'}>
                                        {t('Remove')}
                                    </span>
                                </button>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
