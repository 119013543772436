import axios from 'axios';

export default function axiosConfig() {
    // Defaults for axios
    axios.defaults.baseURL = '/api';
    axios.defaults.headers.common.Accept = 'application/json';
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common['Accept-Language'] = 'nl';
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
    axios.defaults.xsrfCookieName = 'csrftoken';
    axios.defaults.headers.common['Client-Tag'] = APPLICATION_TAG;
    axios.defaults.headers.common['Client-Hash'] = APPLICATION_HASH;
}
