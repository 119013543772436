import React from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

type BookingEnginePrice = {
    price: number;
};

type BookingEngineTotalPriceProps = {
    bookingEngineId: string;
    dates: [Date?, Date?];
    adults: number;
    childrenState: number;
    rooms: number;
    room: string;
};

export default function BookingMaskTotalPrice({
    bookingEngineId,
    dates,
    adults,
    childrenState,
    rooms,
    room,
}: BookingEngineTotalPriceProps) {
    const { t } = useTranslation();
    const bookingEnginePriceParams = new URLSearchParams({
        booking_engine_id: bookingEngineId,
        ...(dates &&
            dates[0] && {
                start_date: dates[0]?.toLocaleDateString('en-ca'),
            }),
        ...(dates &&
            dates[1] && {
                end_date: dates[1]?.toLocaleDateString('en-ca'),
            }),
        num_adults: adults.toString(),
        num_children: childrenState.toString(),
        num_rooms: rooms.toString(),
        room_engine_id: room,
    });

    const { data: bookingEnginePriceData } = useSWR<BookingEnginePrice>(
        dates && dates[0] && dates[1]
            ? `/booking-engine/searchprice/?${bookingEnginePriceParams}`
            : false
    );

    if (bookingEnginePriceData?.price && bookingEnginePriceData?.price > -1) {
        return (
            <div className={'c-booking-mask__total-price'}>
                {t('Totaal vanaf')} €{bookingEnginePriceData.price}
            </div>
        );
    }
}
