// @ts-nocheck
export default function Bad() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get('bad')) {
        // eslint-disable-next-line
        const foo = new Foo();
    }
}
