import React from 'react';
import Countdown, { CountdownRenderProps, zeroPad } from 'react-countdown';
import { useTranslation } from 'react-i18next';

type CountdownProps = {
    promotionTimerSeconds?: string;
};
export default function CountdownTimer({
    promotionTimerSeconds = '0',
}: CountdownProps) {
    const { t } = useTranslation();

    const renderer = ({
        hours,
        minutes,
        seconds,
        days,
    }: CountdownRenderProps) => {
        return (
            <>
                <div className="c-countdown__element">
                    <span className="c-countdown__digit">{zeroPad(days)}</span>
                    <span className="c-countdown__subtext">{t('dagen')}</span>
                </div>
                <div className="c-countdown__element">
                    <span className="c-countdown__digit">{zeroPad(hours)}</span>
                    <span className="c-countdown__subtext">{t('uren')}</span>
                </div>
                <div className="c-countdown__element">
                    <span className="c-countdown__digit">
                        {zeroPad(minutes)}
                    </span>
                    <span className="c-countdown__subtext">{t('minuten')}</span>
                </div>
                <div className="c-countdown__element">
                    <span className="c-countdown__digit">
                        {zeroPad(seconds)}
                    </span>
                    <span className="c-countdown__subtext">
                        {t('seconden')}
                    </span>
                </div>
            </>
        );
    };

    return <Countdown date={promotionTimerSeconds} renderer={renderer} />;
}
