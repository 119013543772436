import React, { useState } from 'react';
import cx from 'classnames';
import {useTranslation} from "react-i18next";
import submitSynxis from '../utils/submitSynxis';

type SynxisSubmitProps = {
    text?: string;
    bookingEngineId?: string;
    arriveDate?: Date | string;
    departDate?: Date | string;
    adults?: string;
    childrenState?: string;
    rooms?: string;
    room?: string;
    rate?: string;
};

export default function SynxisSubmit({
    text = 'Bekijk beschikbaarheid',
    bookingEngineId = '',
    arriveDate = '',
    departDate = '',
    adults = '',
    childrenState = '',
    rooms = '',
    room = '',
    rate = '',
}: SynxisSubmitProps) {
    const [isLoading, setIsLoading] = useState(false);
    const arrDate = arriveDate ? new Date(arriveDate) : undefined;
    const depDate = departDate ? new Date(departDate) : undefined;
    const { t } = useTranslation();

    return (
        <button
            className={cx('c-booking-mask__column c-button c-button--primary', {
                'c-button--loading': isLoading,
            })}
            type={'button'}
            onClick={() => {
                setIsLoading(true);
                submitSynxis({
                    bookingEngineId,
                    // TODO this should be checked when we know how the backend will send this data
                    dates: [arrDate, depDate],
                    adults:
                        adults ||
                        globalConstants.roomConfiguration.adults.default.toString(),
                    children:
                        childrenState ||
                        globalConstants.roomConfiguration.children.default.toString(),
                    rooms:
                        rooms ||
                        globalConstants.roomConfiguration.rooms.default.toString(),
                    room,
                    rate: [rate],
                });
            }}
        >
            {isLoading ? <>&nbsp;</> : t(text)}
        </button>
    );
}
