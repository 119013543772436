import Cookies from 'js-cookie';
import i18n from 'i18next';

//  TODO This should probably come from the backend or set it in some kind of global state
const chain = '17190';
const lang = '9';
const theme = 'SBESTNDRD';
const config = 'STNDRD';

type BookingMaskSubmitData = {
    bookingEngineId: string;
    dates: [Date?, Date?];
    adults: string;
    children: string;
    rooms: string;
    room: string;
    rate?: string[];
};

export default function submitSynxis(
    bookingMaskSubmitData: BookingMaskSubmitData
) {
    const form = document.createElement('form');

    form.method = 'get';
    // TODO this value should come from the backend
    form.action = 'https://reservations.bilderberg.nl/';

    // TODO Some of these values should come from the backend with an api call or through data attributes from the html (for example the CookieConsent)
    const formSubmitValues = {
        chain,
        lang,
        locale: i18n.language,
        theme,
        config,
        adult: bookingMaskSubmitData?.adults?.toString(),
        child: bookingMaskSubmitData?.children?.toString(),
        ...(bookingMaskSubmitData?.room && {
            room: bookingMaskSubmitData?.room,
        }),
        rooms: bookingMaskSubmitData?.rooms?.toString(),
        ...(bookingMaskSubmitData?.dates?.[0] && {
            arrive: bookingMaskSubmitData?.dates?.[0]?.toLocaleDateString(
                'en-ca' // This formats it to yyyy-MM-dd
            ),
        }),
        ...(bookingMaskSubmitData?.dates?.[1] && {
            depart: bookingMaskSubmitData?.dates?.[1]?.toLocaleDateString(
                'en-ca' // This formats it to yyyy-MM-dd
            ),
        }),
        ...(bookingMaskSubmitData?.bookingEngineId && {
            hotel: bookingMaskSubmitData?.bookingEngineId?.toString(),
        }),
        // TODO This value never gets set
        CookieConsent: Cookies.get('CookieConsent') || '',
        ...(bookingMaskSubmitData.dates.length === 2 && {
            start: 'availresults',
        }),
    };

    Object.entries(formSubmitValues).forEach(([key, value]) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = value;
        form.appendChild(input);
    });

    const submit = document.createElement('input');
    submit.type = 'submit';
    form.appendChild(submit);
    document.body.appendChild(form);
    submit.click();
}
