import { MutableRefObject, useEffect, useRef, useState } from 'react';
import getResponsiveInfo from '../utils/getResponsiveInfo';

// This useResponsive is for now only used for booking mask modals not for the mainnavigation
export default function useResponsive() {
    const [responsive, setResponsive] = useState<'desktop' | 'mobile'>(
        'desktop'
    );

    const htmlRef: MutableRefObject<HTMLElement | undefined | null> = useRef();

    useEffect(() => {
        if (!htmlRef.current) {
            htmlRef.current = document.getElementById('responsive-check');

            if (!htmlRef.current) {
                const responsiveCheckElement = document.createElement('div');
                responsiveCheckElement.classList.add('responsive-check');
                responsiveCheckElement.id = 'responsive-check';

                htmlRef.current = document.body.appendChild(
                    responsiveCheckElement
                );
            }
        }

        const responsiveCheckElement = htmlRef.current;

        const handleResize = () => {
            const content = getResponsiveInfo(responsiveCheckElement);
            if (responsiveCheckElement) {
                if (content === 'desktop') {
                    setResponsive('desktop');
                } else if (content === 'mobile') {
                    setResponsive('mobile');
                }
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return responsive;
}
