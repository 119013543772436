import React, { useRef } from 'react';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from 'react-share';

import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import useModal from '../hooks/useModal';

export default function ShareButtons() {
    const dialogRef = useRef<HTMLDialogElement | null>(null);
    const { t } = useTranslation();
    const url = window.location.href;

    const modalRef = useRef<HTMLDivElement | null>(null);

    const { openDialog, closeDialog } = useModal(dialogRef, modalRef);

    return (
        <>
            <div className={'c-share-button'}>
                <button
                    type={'button'}
                    className={'c-button c-button--icon c-button--share'}
                    onClick={() => {
                        openDialog();
                    }}
                >
                    {t('Deel deze pagina')}
                </button>
            </div>
            <dialog className={cx('c-modal')} ref={dialogRef}>
                <div className={'c-modal__content'} ref={modalRef}>
                    <div className={'c-modal__header'}>
                        <h3>{t('Deel deze pagina')}</h3>
                        <button
                            className={'c-modal__close-button'}
                            type={'button'}
                            onClick={() => {
                                closeDialog();
                            }}
                        >
                            <span className={'hidden'}>{t('Sluiten')}</span>
                        </button>
                    </div>
                    <div className={'c-modal__body'}>
                        <ul className={'c-social-share'}>
                            <li className={'c-social-share__item'}>
                                <EmailShareButton
                                    url={url}
                                    className={
                                        'c-social-share__link c-social-share__link--email'
                                    }
                                    resetButtonStyle={false}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className={'c-social-share__icon'}
                                    >
                                        <use href="/static/compiled.svg#envelope" />
                                    </svg>
                                    E-mail
                                </EmailShareButton>
                            </li>
                            <li className={'c-social-share__item'}>
                                <LinkedinShareButton
                                    url={url}
                                    className={
                                        'c-social-share__link c-social-share__link--social-linkedin'
                                    }
                                    resetButtonStyle={false}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className={'c-social-share__icon'}
                                    >
                                        <use href="/static/compiled.svg#linkedin" />
                                    </svg>
                                    LinkedIn
                                </LinkedinShareButton>
                            </li>
                            <li className={'c-social-share__item'}>
                                <FacebookShareButton
                                    url={url}
                                    className={
                                        'c-social-share__link c-social-share__link--social-facebook'
                                    }
                                    resetButtonStyle={false}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className={'c-social-share__icon'}
                                    >
                                        <use href="/static/compiled.svg#facebook" />
                                    </svg>
                                    Facebook
                                </FacebookShareButton>
                            </li>
                            <li className={'c-social-share__item'}>
                                <TwitterShareButton
                                    url={url}
                                    className={
                                        'c-social-share__link c-social-share__link--social-x'
                                    }
                                    resetButtonStyle={false}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className={'c-social-share__icon'}
                                    >
                                        <use href="/static/compiled.svg#x-twitter" />
                                    </svg>
                                    X
                                </TwitterShareButton>
                            </li>
                        </ul>
                    </div>
                </div>
            </dialog>
        </>
    );
}
