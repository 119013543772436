export default function CalculateHotelNavigationHeight() {
    const root: HTMLElement | null = document.querySelector(':root');
    const hotelNavigation: HTMLElement | null = document.querySelector(
        '.js-hotel-navigation'
    );

    function updateHeight({
        root: rootEl,
        hotelNavigation: hotelNavigationEl,
    }: {
        root: HTMLElement;
        hotelNavigation: HTMLElement;
    }) {
        rootEl.style.setProperty(
            '--hotel-navigation-height-dynamic',
            (hotelNavigationEl.offsetHeight / 16).toString() + 'rem'
        );
    }

    if (root && hotelNavigation) {
        updateHeight({ root, hotelNavigation });

        window.addEventListener('resize', () => {
            updateHeight({ root, hotelNavigation });
        });
    }
}
