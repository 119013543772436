import React from 'react';
import BookingMask from './components/BookingMask';
import SynxisSubmit from './components/SynxisSubmit';
import Accordion from './components/Accordion';
import GoogleMaps from './components/GoogleMaps';
import ViewReviewsButton from './components/ViewReviewsButton';
import LightBox from './components/LightBox';
import CountdownTimer from './components/CountdownTimer';
import ShareButtons from './components/ShareButtons';
import GiftCardsForm from './components/GiftCardsForm';
import NewsletterForm from './components/NewsletterForm';
import NewsletterFooterForm from './components/NewsletterFooterForm';
import SaldoChecker from './components/SaldoChecker';

export type ComponentProps = {
    [key: string]: unknown;
};

type ComponentsType = {
    [key: string]: React.FunctionComponent<ComponentProps>;
};

const Components: ComponentsType = {
    GoogleMaps,
    Accordion,
    BookingMask,
    SynxisSubmit,
    ViewReviewsButton,
    LightBox,
    CountdownTimer,
    ShareButtons,
    GiftCardsForm,
    NewsletterForm,
    NewsletterFooterForm,
    SaldoChecker,
};

export default Components;
