import React, { useState, useEffect } from 'react';
import cx from 'classnames';

function getHTML(html: string) {
    return {
        __html: html,
    };
}

type AccordionProps = {
    clonedRootElementNode?: HTMLElement;
};

type AccordionItem = {
    summary: string;
    content: string;
    image?: string;
};

export default function Accordion({
    clonedRootElementNode = undefined,
}: AccordionProps) {
    const [content, setContent] = useState<AccordionItem[]>([]);
    const [open, setOpen] = useState<string>('');
    const [imgAccordion, setImgAccordion] = useState<boolean>(false);

    const isImg = clonedRootElementNode
        ? [...clonedRootElementNode.children].some(
              (elm) =>
                  elm.querySelectorAll('.c-img-accordion__img-wrapper').length
          )
        : false;

    const classPrefix = isImg ? 'c-img' : 'c';

    useEffect(() => {
        if (!clonedRootElementNode) return;

        const items: AccordionItem[] = [];

        [...clonedRootElementNode.children].forEach((elm) => {
            if (elm.querySelectorAll('.c-img-accordion__img-wrapper')) {
                if (!imgAccordion) setImgAccordion(true);
            }

            const item: AccordionItem = {
                summary:
                    (
                        elm.querySelector('.c-accordion__summary') ||
                        elm.querySelector('.c-img-accordion__summary')
                    )?.innerHTML || '',
                content:
                    (
                        elm.querySelector('.c-accordion__content') ||
                        elm.querySelector('.c-img-accordion__content')
                    )?.innerHTML || '',
                image: elm.querySelector('.c-img-accordion__img-wrapper')
                    ?.innerHTML,
            };
            items.push(item);
        });
        setContent(items);
        setOpen(items?.[0]?.summary || '');
    }, [clonedRootElementNode, imgAccordion]);

    return (
        <>
            {content.map((accordionItem) => {
                const itemOpen = accordionItem.summary === open;

                return (
                    <details
                        key={accordionItem.summary}
                        className={cx(classPrefix + '-accordion__details', {
                            [`${classPrefix}-accordion__details-open`]:
                                itemOpen,
                        })}
                        {...(itemOpen ? { open: true } : {})}
                    >
                        {/* eslint-disable-next-line */}
                        <summary
                            className={classPrefix + '-accordion__summary'}
                            onClick={(e) => {
                                e.preventDefault();
                                setOpen(accordionItem.summary);
                            }}
                            dangerouslySetInnerHTML={getHTML(
                                accordionItem.summary
                            )}
                        ></summary>
                        <div className={classPrefix + '-accordion__content'}>
                            <div
                                className={'t-rich-text'}
                                dangerouslySetInnerHTML={getHTML(
                                    accordionItem.content
                                )}
                            />
                        </div>
                        {accordionItem.image && (
                            <div
                                className={'c-img-accordion__img-wrapper'}
                                dangerouslySetInnerHTML={getHTML(
                                    accordionItem.image
                                )}
                            />
                        )}
                    </details>
                );
            })}
        </>
    );
}
