import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

export default function NewsletterFooterForm() {
    const [formData, setFormData] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const { t } = useTranslation();

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        await axios.post('/newsletter/default/', {
            ...formData,
            page_source: currentPageSlug,
        });

        setSubmitted(true);
    }

    return (
        <form className="c-footer-newsletter" onSubmit={(e) => handleSubmit(e)}>
            <label className="c-footer-newsletter__label" htmlFor="newsletter">
                {t('Bilderberg nieuwsbrief')}
            </label>
            {submitted ? (
                <>{t('Dank u wel. U ontvangt vanaf nu onze nieuwsbrief.')}</>
            ) : (
                <div className="c-footer-newsletter__inputs">
                    <input
                        className="c-footer-newsletter__email"
                        type="email"
                        id="newsletter"
                        placeholder={t('E-mailadres')}
                        onChange={(e) =>
                            setFormData({ ...formData, email: e.target.value })
                        }
                    />
                    <button
                        className="c-footer-newsletter__submit c-button c-button--primary c-button--icon c-button--caret-right c-button--hide-text"
                        type="submit"
                    >
                        <span className="c-button__text">{t('Aanmelden')}</span>
                    </button>
                </div>
            )}
        </form>
    );
}
