import { computePosition, flip, offset, shift } from '@floating-ui/react';

export default function Tooltip() {
    const buttons = document.getElementsByClassName(
        'js-tooltip-button'
    ) as HTMLCollectionOf<HTMLElement>;

    [...buttons].forEach((button) => {
        const tooltip = button.dataset.tooltipId
            ? document.getElementById(button.dataset.tooltipId)
            : null;

        function handleClickOutside(event: MouseEvent) {
            const target = event.target as HTMLElement;
            if (
                tooltip &&
                tooltip.classList.contains('c-tooltip--active') &&
                !target.classList.contains('js-tooltip-button') &&
                !tooltip.contains(event.target as Node)
            ) {
                tooltip.classList.remove('c-tooltip--active');
            }
        }
        document.addEventListener('click', handleClickOutside);

        function update() {
            if (tooltip) {
                computePosition(button, tooltip, {
                    placement: 'bottom-start',
                    middleware: [offset(10), flip(), shift()],
                }).then(({ x, y }) => {
                    Object.assign(tooltip.style, {
                        left: `${x}px`,
                        top: `${y}px`,
                    });
                });
            }
        }

        function showTooltip() {
            if (tooltip) {
                const tooltips =
                    document.getElementsByClassName('js-tooltip-content');
                [...tooltips].forEach((tTip) => {
                    tTip.classList.remove('c-tooltip--active');
                });
                tooltip.classList.add('c-tooltip--active');
            }
            update();
        }

        function hideTooltip() {
            if (tooltip) {
                tooltip.classList.remove('c-tooltip--active');
            }
        }

        function clickTooltip() {
            if (tooltip) {
                if (tooltip.classList.contains('c-tooltip--active')) {
                    tooltip.classList.remove('c-tooltip--active');
                } else {
                    tooltip.classList.add('c-tooltip--active');
                }
            }
            update();
        }

        if (button && tooltip) {
            const events: [keyof HTMLElementEventMap, EventListener][] =
                button.dataset.click === 'true'
                    ? [['click', clickTooltip]]
                    : [
                          ['mouseenter', showTooltip],
                          ['focus', showTooltip],
                          ['blur', hideTooltip],
                      ];
            events.forEach(([event, listener]) => {
                button.addEventListener(event, listener);
            });
        }

        if (tooltip) {
            const closeButton = tooltip.getElementsByClassName(
                'js-tooltip-close-button'
            );

            if (closeButton[0]) {
                closeButton[0].addEventListener('click', hideTooltip);
            }
        }
    });

    const tooltips = document.getElementsByClassName('js-tooltip-content');
    const tooltipsContainer = document.getElementById('tooltips-container');

    if (tooltips && tooltipsContainer) {
        [...tooltips].forEach((tooltip) => {
            tooltipsContainer.append(tooltip);
        });
    }
}
