import React from 'react';
import { FloatingPortal } from '@floating-ui/react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import useFloatingDefault from '../utils/useFloatingDefault';
import useResponsive from '../hooks/useResponsive';

type RoomSpecificationsProps = {
    adults: number;
    rooms: number;
    childrenState: number;
    setAdults: (pk: number) => void;
    setRooms: (pk: number) => void;
    setChildrenState: (pk: number) => void;
};

export default function RoomSpecifications({
    adults,
    rooms,
    childrenState,
    setAdults,
    setRooms,
    setChildrenState,
}: RoomSpecificationsProps) {
    const {
        isOpen,
        setIsOpen,
        refs,
        floatingStyles,
        getFloatingProps,
        getReferenceProps,
    } = useFloatingDefault({ placement: 'top-start' });
    const { roomConfiguration } = globalConstants;
    const responsive = useResponsive();

    const { t } = useTranslation();

    const lst = [];

    if (adults > 0) {
        if (adults > 1) {
            lst.push(adults + ' ' + t('volwassenen'));
        } else {
            lst.push(adults + ' ' + t('volwassene'));
        }
    }
    if (childrenState > 0) {
        if (childrenState > 1) {
            lst.push(childrenState + ' ' + t('kinderen'));
        } else {
            lst.push(childrenState + ' ' + t('kind'));
        }
    }
    if (rooms > 0) {
        if (rooms > 1) {
            lst.push(rooms + ' ' + t('kamers'));
        } else {
            lst.push(rooms + ' ' + t('kamer'));
        }
    }

    return (
        <>
            <div
                className={cx('c-booking-mask__input c-booking-mask__column', {
                    'c-booking-mask__input--active': isOpen,
                })}
                ref={refs.setReference}
                {...getReferenceProps()}
            >
                <div className={'c-booking-mask__input-inner'}>
                    {lst.join(', ')}
                </div>
            </div>
            {isOpen && (
                <FloatingPortal>
                    <div
                        className={'c-booking-mask__selector-container'}
                        ref={refs.setFloating}
                        style={
                            responsive !== 'mobile' ? floatingStyles : undefined
                        }
                        {...getFloatingProps()}
                    >
                        <div className={'c-booking-mask-popup'}>
                            <div className={' c-booking-mask-popup__header'}>
                                <h3>{t('Kies uw reisgezelschap')}</h3>
                                <button
                                    className="c-booking-mask-popup__close-button"
                                    type="button"
                                    onClick={() => setIsOpen(false)}
                                >
                                    <span className="hidden">{t('Close')}</span>
                                </button>
                            </div>
                            <div className={' c-booking-mask-popup__body'}>
                                <div
                                    className={'c-room-specifications-selector'}
                                >
                                    <span>{t('Volwassenen')}</span>
                                    <div className="c-room-specifications-selector__toggle-group">
                                        <button
                                            aria-label={
                                                t('Volwassenen') + ' -1'
                                            }
                                            className={
                                                'c-room-specifications-selector__button c-room-specifications-selector__button--minus'
                                            }
                                            type="button"
                                            onClick={() => {
                                                if (
                                                    adults >
                                                    roomConfiguration.adults.min
                                                ) {
                                                    setAdults(adults - 1);
                                                }
                                            }}
                                        />
                                        <div
                                            className={
                                                'c-room-specifications-selector__count'
                                            }
                                        >
                                            {adults}
                                        </div>
                                        <button
                                            aria-label={
                                                t('Volwassenen') + ' +1'
                                            }
                                            className={
                                                'c-room-specifications-selector__button c-room-specifications-selector__button--plus'
                                            }
                                            type="button"
                                            onClick={() => {
                                                if (
                                                    adults <
                                                    roomConfiguration.adults.max
                                                ) {
                                                    setAdults(adults + 1);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <div
                                    className={'c-room-specifications-selector'}
                                >
                                    <span>{t('Kinderen')}</span>
                                    <div className="c-room-specifications-selector__toggle-group">
                                        <button
                                            aria-label={t('Kinderen') + ' -1'}
                                            className={
                                                'c-room-specifications-selector__button c-room-specifications-selector__button--minus'
                                            }
                                            type="button"
                                            onClick={() => {
                                                if (
                                                    childrenState >
                                                    roomConfiguration.children
                                                        .min
                                                ) {
                                                    setChildrenState(
                                                        childrenState - 1
                                                    );
                                                }
                                            }}
                                        />
                                        <div
                                            className={
                                                'c-room-specifications-selector__count'
                                            }
                                        >
                                            {childrenState}
                                        </div>
                                        <button
                                            aria-label={t('Kinderen') + ' +1'}
                                            className={
                                                'c-room-specifications-selector__button c-room-specifications-selector__button--plus'
                                            }
                                            type="button"
                                            onClick={() => {
                                                if (
                                                    childrenState <
                                                    roomConfiguration.children
                                                        .max
                                                ) {
                                                    setChildrenState(
                                                        childrenState + 1
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <div
                                    className={'c-room-specifications-selector'}
                                >
                                    <span>{t('Kamers')}</span>
                                    <div className="c-room-specifications-selector__toggle-group">
                                        <button
                                            aria-label={t('Kamers') + ' -1'}
                                            className={
                                                'c-room-specifications-selector__button c-room-specifications-selector__button--minus'
                                            }
                                            type="button"
                                            onClick={() => {
                                                if (
                                                    rooms >
                                                    roomConfiguration.rooms.min
                                                ) {
                                                    setRooms(rooms - 1);
                                                }
                                            }}
                                        />
                                        <div
                                            className={
                                                'c-room-specifications-selector__count'
                                            }
                                        >
                                            {rooms}
                                        </div>
                                        <button
                                            aria-label={t('Kamers') + ' +1'}
                                            className={
                                                'c-room-specifications-selector__button c-room-specifications-selector__button--plus'
                                            }
                                            type="button"
                                            onClick={() => {
                                                if (
                                                    rooms <
                                                    roomConfiguration.rooms.max
                                                ) {
                                                    setRooms(rooms + 1);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {responsive === 'mobile' && (
                                <div className={'c-booking-mask-popup__footer'}>
                                    <button
                                        className={
                                            'c-booking-mask-popup__confirm c-button c-button--primary'
                                        }
                                        type="button"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        {t('Selecteer')}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </FloatingPortal>
            )}
        </>
    );
}
