export default function BackToTop() {
    const backToTop = document.getElementById('back-to-top');
    const hero = document.getElementsByClassName('c-hero');

    if (hero.length > 0 && hero[0] && backToTop) {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    backToTop.classList.remove('c-back-to-top--active');
                } else {
                    backToTop.classList.add('c-back-to-top--active');
                }
            });
        });

        // Start observing the target div
        observer.observe(hero[0]);

        backToTop.onclick = (e) => {
            e.preventDefault();
            window.scrollTo({ top: 0, behavior: 'smooth' });
        };
    }
}
