export default function LanguageSelect() {
    const languageSelect =
        document.getElementsByClassName('js-language-select');

    if (languageSelect) {
        const languageSelectToggles = document.querySelectorAll(
            '.js-language-select__toggle'
        );

        document.addEventListener('click', (e: Event) => {
            if (
                !(e.target as Element).classList.contains(
                    'js-language-select'
                ) &&
                !(e.target as Element).classList.contains(
                    'js-language-select__toggle'
                ) &&
                !(e.target as Element).classList.contains(
                    'js-language-select__current'
                )
            ) {
                languageSelectToggles.forEach((el) => {
                    const tempEl = el as HTMLInputElement;
                    tempEl.checked = false;
                });
            }
        });

        languageSelectToggles.forEach((el) => {
            el.addEventListener('change', (e) => {
                const target = e.target as HTMLInputElement;
                if (target.checked) {
                    const subNavigationToggles: NodeListOf<HTMLElement> =
                        document.querySelectorAll('.js-sub-navigation-toggle');

                    if (
                        subNavigationToggles &&
                        subNavigationToggles.length > 0
                    ) {
                        subNavigationToggles.forEach(
                            (subNavigationToggle: HTMLElement) => {
                                const tempEl =
                                    subNavigationToggle as HTMLInputElement;
                                tempEl.checked = false;
                            }
                        );
                    }
                }
            });
        });
    }
}
