import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import i18n from '../utils/i18nConfig';

export default function SwiperInit() {
    const swipers = document.querySelectorAll('.c-columned-content-swiper');

    swipers.forEach((swiperInstance) => {
        const swiperNavigation = document.createElement('div');
        swiperNavigation.classList.add('swiper-navigation');

        const prevButton = document.createElement('button');
        prevButton.classList.add('swiper-button-prev');
        swiperNavigation.append(prevButton);

        const nextButton = document.createElement('button');
        nextButton.classList.add('swiper-button-next');
        swiperNavigation.append(nextButton);

        swiperInstance.prepend(swiperNavigation);

        const swiperPagination = document.createElement('div');
        swiperPagination.classList.add('swiper-pagination');

        swiperInstance.append(swiperPagination);
    });

    // eslint-disable-next-line no-new
    new Swiper('.c-columned-content-swiper', {
        modules: [Navigation, Pagination],
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            320: {
                spaceBetween: 16,
                slidesOffsetBefore: 16,
                slidesOffsetAfter: 16,
                slidesPerView: 1.25,
            },
            544: {
                spaceBetween: 16,
                slidesOffsetBefore: 16,
                slidesOffsetAfter: 16,
                slidesPerView: 1.75,
            },
            768: {
                spaceBetween: 24,
                slidesOffsetBefore: 24,
                slidesOffsetAfter: 24,
                slidesPerView: 2.25,
            },
            1024: {
                spaceBetween: 32,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
                slidesPerView: 3,
            },
        },
        a11y: {
            prevSlideMessage: i18n.t('Vorige'),
            nextSlideMessage: i18n.t('Volgende'),
        },
    });
}
