import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import { SWRConfig } from 'swr';
import Components, { type ComponentProps } from './components';
import axiosConfig from './utils/axiosConfig';
import sentryConfig from './utils/sentryConfig';
import './utils/i18nConfig';
import MainNavigation from './vanilla/MainNavigation';

import 'swiper/css';
import ReadMore from './vanilla/ReadMore';
import PromotionPopup from './vanilla/PromotionPopup';
import BackToTop from './vanilla/BackToTop';
import Tooltip from './vanilla/Tooltip';
import LanguageSelect from './vanilla/LanguageSelect';
import Modal from './vanilla/Modal';
import CalculateHotelNavigationHeight from './vanilla/CalculateHotelNavigationHeight';
import SwiperInit from './vanilla/Swiper';
import Bad from './vanilla/Bad';

// For each directory that we want to include as assets, perform a call to import.meta.glob
//
// Please note:
//
// 1. This is a notation only supported by Vite, and not through web/ES standards. Visit
//    https://vitejs.dev/guide/features#glob-import-caveats for more information.
//
// 2. The contents of the copied directory is flattened and stored directly under /assets/
//
// 3. We are not assigning the return value from the call to variable as the example from
//    https://github.com/vitejs/vite/issues/2375#issuecomment-908579361, since this adds more information to the
//    manifest and emits additional files for the matching files. Unassigned calls for `import.meta.glob` do not
//    trigger this. This might make the current form slightly crippled Dynamic Imports
//    (https://vitejs.dev/guide/features#dynamic-import)
//
// 4. It's not possible to loop through a list of glob pattern-strings, since this ends in a fatal error as
//    `import.meta.glob` only supports literals and not variables.

// Copy the assets/images directory to bundles/assets
import.meta.glob('../images/**/*');

sentryConfig();
axiosConfig();

function renderComponentInElement(el: HTMLElement) {
    const id = el.dataset.component;
    if (!id) return; // If id is falsy, exit early

    const Component = Components[id];

    if (!Component) return;

    // get props from elements data attribute, like the post_id
    const props: ComponentProps = { ...el.dataset, el };
    props.clonedRootElementNode = el.cloneNode(true);

    const root = createRoot(el);
    root.render(
        <SWRConfig
            value={{
                dedupingInterval: 5000, // ignores additional calls with the same key (prevents hammering)
                shouldRetryOnError: false,
                fetcher: (url) =>
                    axios
                        .get(url)
                        .then((res) => res.data)
                        .catch((error) => {
                            throw new Error(error);
                        }),
            }}
        >
            <StrictMode>{React.createElement(Component, props)}</StrictMode>
        </SWRConfig>
    );
}

// populate React components
Array.from(document.querySelectorAll('.__react-root')).forEach(
    (el: Element) => {
        renderComponentInElement(el as HTMLElement);
    }
);

MainNavigation();
ReadMore();
PromotionPopup();
BackToTop();
Tooltip();
LanguageSelect();
Modal();
CalculateHotelNavigationHeight();
SwiperInit();
Bad();
