import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { FloatingPortal } from '@floating-ui/react';
import useFloatingDefault from '../utils/useFloatingDefault';

type GiftCardBalanceResponse = {
    product: string;
    number: string;
    status: string;
    value: number;
    expiry_date: string;
};

export default function SaldoChecker() {
    const [formData, setFormData] = useState({});
    const [responseData, setResponseData] = useState<
        GiftCardBalanceResponse | undefined
    >(undefined);
    const [submitted, setSubmitted] = useState(false);
    const { t } = useTranslation();

    const {
        isOpen,
        refs,
        floatingStyles,
        getFloatingProps,
        getReferenceProps,
    } = useFloatingDefault({ enableHover: true });
    const {
        isOpen: isOpen2,
        refs: refs2,
        floatingStyles: floatingStyles2,
        getFloatingProps: getFloatingProps2,
        getReferenceProps: getReferenceProps2,
    } = useFloatingDefault({ enableHover: true });

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        try {
            const response = await axios.post<GiftCardBalanceResponse>(
                '/gift-card-balance/',
                {
                    ...formData,
                }
            );

            setSubmitted(true);
            setResponseData(response.data);
        } catch (error) {
            setSubmitted(true);
            console.error('Er is iets misgegaan', error);
        }
    }

    return (
        <>
            <form
                onSubmit={(e) => handleSubmit(e)}
                className={'c-giftcard-saldo-checker'}
            >
                <div className={'c-giftcard-saldo-checker__row'}>
                    <div className={'c-giftcard-saldo-checker__label-group'}>
                        <label htmlFor="card-number">{t('Kaartnummer')}</label>
                        <div
                            className="c-tooltip-button"
                            ref={refs.setReference}
                            {...getReferenceProps()}
                        >
                            <span className="c-tooltip-button__text">i</span>
                        </div>
                        {isOpen && (
                            <FloatingPortal>
                                <div
                                    className={'c-tooltip c-tooltip--active'}
                                    ref={refs.setFloating}
                                    style={floatingStyles}
                                    {...getFloatingProps()}
                                >
                                    <span className="c-tooltip__title">
                                        {t('Kaartnummer')}
                                    </span>
                                    <div className="c-tooltip__content">
                                        {t('Het kaartnummer op uw cadeaucard.')}
                                    </div>
                                </div>
                            </FloatingPortal>
                        )}
                    </div>
                    <input
                        id="card-number"
                        type="text"
                        name="card-number"
                        placeholder={t('Kaartnummer')}
                        maxLength={21}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                card_number: e.target.value,
                            })
                        }
                    />
                </div>

                <div className={'c-giftcard-saldo-checker__row'}>
                    <div className={'c-giftcard-saldo-checker__label-group'}>
                        <label htmlFor="card-verification-number">
                            {t('Kaartverificatienummer')}
                        </label>
                        <div
                            className="c-tooltip-button"
                            ref={refs2.setReference}
                            {...getReferenceProps2()}
                        >
                            <span className="c-tooltip-button__text">i</span>
                        </div>
                        {isOpen2 && (
                            <FloatingPortal>
                                <div
                                    className={'c-tooltip c-tooltip--active'}
                                    ref={refs2.setFloating}
                                    style={floatingStyles2}
                                    {...getFloatingProps2()}
                                >
                                    <span className="c-tooltip__title">
                                        {t('Kaartverificatienummer')}
                                    </span>
                                    <div className="c-tooltip__content">
                                        {t(
                                            'Het verificatienummer is te vinden achter op het pasje wanneer u de kraslaag verwijdert. Heeft u geen verificatienummer bij uw cadeaucard ontvangen? Neem dan contact op via info@bilderberg.nl.'
                                        )}
                                    </div>
                                </div>
                            </FloatingPortal>
                        )}
                    </div>
                    <input
                        id="card-verification-number"
                        type="text"
                        name="card-verification-number"
                        placeholder={t('Kaartverificatienummer')}
                        maxLength={5}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                card_verification_number: e.target.value,
                            })
                        }
                    />
                </div>
                <button
                    type={'submit'}
                    className={'c-button c-button--primary'}
                >
                    {t('Verstuur')}
                </button>
            </form>

            {submitted && (
                <div className={'c-giftcard-saldo-checker__results'}>
                    <h4 className={'c-giftcard-saldo-checker__result-header'}>
                        {t('Uw cadeaukaartgegevens')}
                    </h4>

                    <dl>
                        <dt className={'c-giftcard-saldo-checker__key'}>
                            {t('Nummer')}
                        </dt>
                        <dd className={'c-giftcard-saldo-checker__value'}>
                            {responseData?.number || t('onbekend')}
                        </dd>
                        <dt className={'c-giftcard-saldo-checker__key'}>
                            {t('Beschrijving')}
                        </dt>
                        <dd className={'c-giftcard-saldo-checker__value'}>
                            {responseData?.product || t('onbekend')}
                        </dd>
                        <dt className={'c-giftcard-saldo-checker__key'}>
                            {t('Openstaand saldo')}
                        </dt>
                        <dd className={'c-giftcard-saldo-checker__value'}>
                            {responseData?.value ||
                            responseData?.value === 0 ? (
                                <>
                                    {' '}
                                    EUR{' '}
                                    {parseFloat(
                                        (responseData.value / 100).toString()
                                    ).toFixed(2)}
                                </>
                            ) : (
                                t('onbekend')
                            )}
                        </dd>
                        <dt className={'c-giftcard-saldo-checker__key'}>
                            {t('Vervaldatum')}
                        </dt>
                        <dd className={'c-giftcard-saldo-checker__value'}>
                            {responseData?.expiry_date || t('onbekend')}
                        </dd>
                    </dl>
                </div>
            )}
        </>
    );
}
