import i18n from '../utils/i18nConfig';

export default function ReadMore() {
    const paragraphPreviewElements = document.querySelectorAll(
        '.js-paragraph-preview'
    );

    const isEllipsisActive = (elm: any) => {
        return elm.offsetHeight < elm.scrollHeight;
    };

    paragraphPreviewElements.forEach((paragraphPreviewElement) => {
        const paragraphs = paragraphPreviewElement.querySelectorAll(
            '.js-paragraph-preview-paragraph'
        );

        const firstParagraph = paragraphs[0];

        if (
            firstParagraph &&
            (isEllipsisActive(firstParagraph) || paragraphs.length > 1)
        ) {
            const p = document.createElement('p');
            const link = document.createElement('a');
            link.classList.add('t-link');
            link.appendChild(document.createTextNode(i18n.t('Lees verder')));
            link.title = i18n.t('Lees meer...');
            link.onclick = () => {
                paragraphs.forEach((paragraph) => {
                    paragraph.classList.remove(
                        'c-paragraph-preview__paragraph--truncated'
                    );
                });
                firstParagraph.parentNode?.removeChild(p);
            };
            p.appendChild(link);
            firstParagraph.insertAdjacentElement('afterend', p);
        }
    });
}
