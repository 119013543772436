import cx from 'classnames';
import React, { MutableRefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios, { AxiosError } from 'axios';
import { ResponseData } from '../types/types';

type GiftCardsConfirmationProps = {
    dialogRef: MutableRefObject<HTMLDialogElement | null>;
    modalRef: MutableRefObject<HTMLDivElement | null>;
    closeDialog: () => void;
    responseData: ResponseData | undefined;
    processingCosts: string | undefined;
};

export default function GiftCardsConfirmation({
    dialogRef,
    modalRef,
    closeDialog,
    responseData,
    processingCosts,
}: GiftCardsConfirmationProps) {
    const [errors, setErrors] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { t } = useTranslation();

    const responseDataTotalPrice = responseData?.giftcards
        .reduce((total, giftCard) => {
            return total + giftCard.amount * parseInt(giftCard.price, 10);
        }, 0)
        .toFixed(2);

    const responseDataTotalPricePlusProcessingCosts =
        responseDataTotalPrice && processingCosts
            ? parseFloat(responseDataTotalPrice) + parseFloat(processingCosts)
            : responseDataTotalPrice || 0;

    async function handleSubmit() {
        setIsSubmitting(true);
        try {
            const response = await axios.post('/order/', {
                ...responseData,
            });

            if (!response.data.created) {
                const validationError = {
                    general: [t('Aanmaken mislukt')],
                };
                throw new Error(JSON.stringify(validationError));
            }

            window.location.href = t('/order-afgerond/');
        } catch (error) {
            const catchErrors = error as AxiosError;
            if (catchErrors) {
                setErrors(true);
            }

            setIsSubmitting(false);
        }
    }

    return (
        <dialog className={cx('c-modal c-modal--full-width')} ref={dialogRef}>
            <div className={'c-modal__content'} ref={modalRef}>
                <div className={'c-modal__header'}>
                    <h3>{t('Controleer de bestelling van uw Cadeaucard')}</h3>
                    <button
                        className={'c-modal__close-button'}
                        type={'button'}
                        onClick={() => {
                            closeDialog();
                        }}
                    >
                        <span className={'hidden'}>{t('Sluiten')}</span>
                    </button>
                </div>
                <div className={'c-modal__body'}>
                    <div className={'c-giftcard-summary'}>
                        <div className={'c-giftcard-summary__column'}>
                            <h4 className={'t-heading-delta t-left-aligned'}>
                                {t('Uw bestelling')}
                            </h4>
                            <table className={'c-giftcard-summary__table'}>
                                <thead>
                                    <tr>
                                        <th>{t('Aantal')}</th>
                                        <th>{t('T.w.v.')}</th>
                                        <th>{t('Totaal')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {responseData?.giftcards.map(
                                        (giftCard, i) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <tr key={'giftcard-' + i}>
                                                <td>{giftCard.amount}</td>
                                                <td>€ {giftCard.price}</td>
                                                <td>
                                                    €{' '}
                                                    {(
                                                        giftCard.amount *
                                                        parseInt(
                                                            giftCard.price,
                                                            10
                                                        )
                                                    ).toFixed(2)}
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                                <tfoot>
                                    {processingCosts && (
                                        <tr>
                                            <td colSpan={2}>
                                                {t('Verwerkingskosten')}
                                            </td>
                                            <td>€ {processingCosts}</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td colSpan={2} />
                                        <td
                                            colSpan={1}
                                            className={
                                                'c-giftcard-summary-total-price'
                                            }
                                        >
                                            €{' '}
                                            {
                                                responseDataTotalPricePlusProcessingCosts
                                            }
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className={'c-giftcard-summary__column'}>
                            <h4 className={'t-heading-delta t-left-aligned'}>
                                {t('Uw gegevens')}
                            </h4>
                            <dl className={'c-giftcard-summary__dl'}>
                                <dt>{t('Bedrijfsnaam')}</dt>
                                <dd>{responseData?.company}</dd>
                                <dt>{t('Aanhef')}</dt>
                                <dd>
                                    {responseData?.gender === 'f'
                                        ? t('Madam')
                                        : responseData?.gender === 'm'
                                          ? t('Sir')
                                          : ''}
                                </dd>
                                <dt>{t('Initialen')}</dt>
                                <dd>{responseData?.initials}</dd>
                                <dt>{t('Tussenvoegsel')}</dt>
                                <dd>{responseData?.insertion}</dd>
                                <dt>{t('Achternaam')}</dt>
                                <dd>{responseData?.last_name}</dd>
                                <dt>{t('Email')}</dt>
                                <dd>{responseData?.email}</dd>
                                <dt>{t('Telefoonnummer')}</dt>
                                <dd>{responseData?.telephone}</dd>
                            </dl>
                        </div>
                        <div className={'c-giftcard-summary__column'}>
                            <h4 className={'t-heading-delta t-left-aligned'}>
                                {t('Adres')}
                            </h4>
                            <dl className={'c-giftcard-summary__dl'}>
                                <dt>{t('Straatnaam')}</dt>
                                <dd>{responseData?.street}</dd>
                                <dt>{t('Huisnummer')}</dt>
                                <dd>{responseData?.house_number}</dd>
                                <dt>{t('Toevoeging')}</dt>
                                <dd>{responseData?.house_number_addition}</dd>
                                <dt>{t('Postcode')}</dt>
                                <dd>{responseData?.postal_code}</dd>
                                <dt>{t('Plaats')}</dt>
                                <dd>{responseData?.city}</dd>
                                <dt>{t('Land')}</dt>
                                <dd>{responseData?.country}</dd>
                            </dl>
                        </div>
                        {responseData?.send_to_receiver && (
                            <>
                                <div className={'c-giftcard-summary__column'}>
                                    <h4
                                        className={
                                            't-heading-delta t-left-aligned'
                                        }
                                    >
                                        {t('Ontvanger')}
                                    </h4>
                                    <dl className={'c-giftcard-summary__dl'}>
                                        <dt>{t('Bedrijfsnaam')}</dt>
                                        <dd>
                                            {responseData?.receiver_company}
                                        </dd>
                                        <dt>{t('Aanhef')}</dt>
                                        <dd>
                                            {responseData?.receiver_gender ===
                                            'f'
                                                ? t('Madam')
                                                : responseData?.receiver_gender ===
                                                    'm'
                                                  ? t('Sir')
                                                  : ''}
                                        </dd>
                                        <dt>{t('Initialen')}</dt>
                                        <dd>
                                            {responseData?.receiver_initials}
                                        </dd>
                                        <dt>{t('Tussenvoegsel')}</dt>
                                        <dd>
                                            {responseData?.receiver_insertion}
                                        </dd>
                                        <dt>{t('Achternaam')}</dt>
                                        <dd>
                                            {responseData?.receiver_last_name}
                                        </dd>
                                        <dt>{t('Straatnaam')}</dt>
                                        <dd>{responseData?.receiver_street}</dd>
                                        <dt>{t('Huisnummer')}</dt>
                                        <dd>
                                            {
                                                responseData?.receiver_house_number
                                            }
                                        </dd>
                                        <dt>{t('Toevoeging')}</dt>
                                        <dd>
                                            {
                                                responseData?.receiver_house_number_addition
                                            }
                                        </dd>
                                        <dt>{t('Postcode')}</dt>
                                        <dd>
                                            {responseData?.receiver_postal_code}
                                        </dd>
                                        <dt>{t('Plaats')}</dt>
                                        <dd>{responseData?.receiver_city}</dd>
                                        <dt>{t('Land')}</dt>
                                        <dd>
                                            {responseData?.receiver_country}
                                        </dd>
                                    </dl>
                                </div>
                                <div className={'c-giftcard-summary__column'}>
                                    <h4
                                        className={
                                            't-heading-delta t-left-aligned'
                                        }
                                    >
                                        {t('Bericht')}
                                    </h4>
                                    {responseData?.receiver_message}
                                </div>
                            </>
                        )}
                    </div>
                    {errors && (
                        <div className={'giftcard-errors'}>
                            {t('Er is iets misgegaan.')}
                        </div>
                    )}
                    <div className={'c-modal__footer'}>
                        <button
                            className={'c-button'}
                            type={'button'}
                            onClick={() => closeDialog()}
                        >
                            {t('Annuleren')}
                        </button>
                        <button
                            className={'c-button c-button--primary'}
                            type={'button'}
                            onClick={() => handleSubmit()}
                            disabled={isSubmitting}
                        >
                            {t('Bestel')}
                        </button>
                    </div>
                </div>
            </div>
        </dialog>
    );
}
