// Main navigation

import getResponsiveInfo from '../utils/getResponsiveInfo';

function handleResize() {
    const responsiveCheckElement = document.getElementById('responsive-check');

    if (!responsiveCheckElement) {
        const newResponsiveCheckElement = document.createElement('div');
        newResponsiveCheckElement.classList.add('responsive-check');
        newResponsiveCheckElement.id = 'responsive-check';

        document.body.appendChild(newResponsiveCheckElement);
    }

    const content = getResponsiveInfo(responsiveCheckElement);
    const mainNavigationToggle = document.querySelector(
        '.js-main-navigation-toggle'
    ) as HTMLInputElement;

    if (responsiveCheckElement && mainNavigationToggle) {
        if (content === 'desktop') {
            mainNavigationToggle.checked = false;
        } else if (content === 'mobile') {
            // mainNavigationToggle.checked = true;
        }
    }
}

export default function MainNavigation() {
    const mainNavigation = document.getElementById('js-main-navigation');

    if (mainNavigation) {
        const subNavigationToggles: NodeListOf<HTMLElement> =
            mainNavigation.querySelectorAll('.js-sub-navigation-toggle');

        // const mainNavigationToggle = document.querySelectorAll(
        //     '.js-main-navigation-toggle'
        // );

        mainNavigation.addEventListener('change', (e) => {
            // console.log("changing", (e.target as Element).classList.contains('js-sub-navigation-toggle'));
            if (
                (e.target as Element).classList.contains(
                    'js-sub-navigation-toggle'
                )
            ) {
                subNavigationToggles.forEach((el: HTMLElement) => {
                    if (el !== e.target) {
                        const tempEl = el as HTMLInputElement;
                        tempEl.checked = false;
                    }
                });
            }
        });

        document.addEventListener('click', (e) => {
            if (!mainNavigation.contains(e.target as Node)) {
                subNavigationToggles.forEach((el: HTMLElement) => {
                    const tempEl = el as HTMLInputElement;
                    tempEl.checked = false;
                });
            }
        });
        window.addEventListener('resize', handleResize);
    }
}
