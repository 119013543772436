import { useCallback, useEffect, useState } from 'react';

export default function useModal(
    dialogRef: React.MutableRefObject<HTMLDialogElement | null>,
    modalRef: React.MutableRefObject<HTMLDivElement | null>
) {
    const [isOpen, setIsOpen] = useState(false);
    const [currentDialogRefState, setCurrentDialogRefState] =
        useState<HTMLDialogElement | null>(null);

    if (dialogRef.current && dialogRef.current !== currentDialogRefState) {
        setCurrentDialogRefState(dialogRef.current);
    }

    const openDialog = useCallback(() => {
        if (dialogRef.current) {
            dialogRef.current.showModal();
            setIsOpen(true);
        }
    }, [dialogRef]);

    const closeDialog = useCallback(() => {
        if (dialogRef.current) {
            dialogRef.current.close();
            setIsOpen(false);
        }
    }, [dialogRef]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node)
            ) {
                if (dialogRef.current) {
                    dialogRef.current.close();
                    setIsOpen(false);
                }
            }
        };
        const copyDialogRef = dialogRef.current;

        if (modalRef.current) {
            document.addEventListener('mousedown', handleClickOutside);
            copyDialogRef?.addEventListener('close', closeDialog);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            copyDialogRef?.removeEventListener('close', closeDialog);
        };
    }, [closeDialog, dialogRef, modalRef, currentDialogRefState]);

    return { openDialog, closeDialog, isOpen };
}
