import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import cx from 'classnames';
import useModal from '../hooks/useModal';

type ViewReviewsButtonProps = {
    hotelId?: string;
};

type ReviewData = {
    results: Review[];
};

type Review = {
    pk: number;
    text: string;
    author_name: string;
    ratings_overall_value: number;
    ratings_overall_value_out_of: number;
};

export default function ViewReviewsButton({
    hotelId = undefined,
}: ViewReviewsButtonProps) {
    const dialogRef = useRef<HTMLDialogElement | null>(null);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const { t } = useTranslation();
    const { data: reviewsData, isLoading } = useSWR<ReviewData>(
        hotelId && `/hotel/${hotelId}/reviews/?page_size=25`
    );

    const { openDialog, closeDialog } = useModal(dialogRef, modalRef);

    if (isLoading || !reviewsData) return <div className={'loaderanimation'} />;

    return (
        <>
            <button
                className="t-link"
                type={'button'}
                onClick={() => {
                    openDialog();
                }}
            >
                {t('Lees meer beoordelingen')}
            </button>
            <dialog className={cx('c-modal')} ref={dialogRef}>
                <div className={'c-modal__content'} ref={modalRef}>
                    <div className={'c-modal__header'}>
                        <h3>{t('Beoordelingen')}</h3>
                        <button
                            className={'c-modal__close-button'}
                            type={'button'}
                            onClick={() => {
                                closeDialog();
                            }}
                        >
                            <span className={'hidden'}>{t('Sluiten')}</span>
                        </button>
                    </div>
                    <div className={'c-modal__body'}>
                        <p className={'t-paragraph--intro'}>
                            {t(
                                'Al onze gastbeoordelingen zijn geverifieerd door TrustYou, een onafhankelijk platform met meer dan 220 aangesloten reviewsites.'
                            )}
                        </p>
                        <div className={'c-review-quote-list'}>
                            {reviewsData.results.map((review) => {
                                return (
                                    <div
                                        className={'c-review-quote'}
                                        key={review.pk}
                                    >
                                        <blockquote
                                            className={'c-review-quote__text'}
                                        >
                                            "{review.text}"
                                        </blockquote>
                                        <p className={'c-review-quote__author'}>
                                            {review.author_name}
                                        </p>
                                        <p className={'c-review-quote__rating'}>
                                            {review.ratings_overall_value < 10
                                                ? review.ratings_overall_value.toFixed(
                                                      1
                                                  )
                                                : review.ratings_overall_value}{' '}
                                            /{' '}
                                            {
                                                review.ratings_overall_value_out_of
                                            }
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </dialog>
        </>
    );
}
