import DatePicker, { registerLocale } from 'react-datepicker';
import React, { useRef, useState } from 'react';
import { nl } from 'date-fns/locale/nl';
import { de } from 'date-fns/locale/de';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import useResponsive from '../hooks/useResponsive';
import useModal from '../hooks/useModal';

registerLocale('nl', nl);
registerLocale('de', de);

type BookingMaskDatePickerProps = {
    roomId?: string;
    bookingEngineId: string;
    dates: [Date?, Date?];
    setDates: (dates: [Date?, Date?]) => void;
};

type BookingEngineCalendar = {
    grid: GridItem[][];
};

type GridItem = {
    0: number;
    1: number;
    2: boolean;
};

function findPrice(grid: GridItem[][], target: number) {
    const item = grid
        .flatMap((row) => row)
        .find((gridItem) => gridItem[0] === target);
    return item ? item[2] : undefined;
}

export default function BookingMaskDatePicker({
    roomId = '',
    bookingEngineId,
    dates,
    setDates,
}: BookingMaskDatePickerProps) {
    const [selectedYear, setSelectedYear] = useState<number>(
        new Date().getFullYear()
    );
    const [selectedMonth, setSelectedMonth] = useState<number>(
        new Date().getMonth() + 1
    );

    const responsive = useResponsive();
    const { t, i18n } = useTranslation();

    const dialogRef = useRef<HTMLDialogElement | null>(null);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const { openDialog, closeDialog, isOpen } = useModal(dialogRef, modalRef);

    const bookingEngineCalendarParams = new URLSearchParams({
        year: selectedYear.toString(),
        month: selectedMonth.toString(),
        booking_engine_id: bookingEngineId,
        ...(roomId && { room_id: roomId }),
    });

    const { data: bookingEngineCalendarData } = useSWR<BookingEngineCalendar>(
        bookingEngineId
            ? `/booking-engine/calendar/?${bookingEngineCalendarParams}`
            : false
    );

    const renderDayContents = (day: number) => {
        const price = bookingEngineCalendarData?.grid
            ? findPrice(bookingEngineCalendarData.grid, day)
            : undefined;

        return (
            <div className={'react-datepicker__price-container'}>
                <span className={'react-datepicker__price-day'}>{day}</span>
                {price && (
                    <span className={'react-datepicker__price-price'}>
                        € {price}
                    </span>
                )}
            </div>
        );
    };

    const options: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'long',
    };
    const selectedStartDate = dates[0]?.toLocaleDateString(
        i18n.language,
        options
    );
    const selectedEndDate = dates[1]?.toLocaleDateString(
        i18n.language,
        options
    );

    return (
        <>
            <button
                className={cx(
                    'c-booking-mask__input c-booking-mask__input--allow-overflow c-booking-mask__column',
                    { 'c-booking-mask__input--active': isOpen }
                )}
                type={'button'}
                onClick={() => {
                    openDialog();
                }}
            >
                <span className={'c-booking-mask__input-inner'}>
                    {(!selectedStartDate || !selectedEndDate) &&
                        t('Kies een datum')}
                    {selectedStartDate && selectedEndDate && (
                        <>
                            {selectedStartDate} - {selectedEndDate}
                        </>
                    )}
                </span>
            </button>
            <dialog
                className={cx('c-modal c-modal--content-width')}
                ref={dialogRef}
            >
                <div className={'c-modal__content'} ref={modalRef}>
                    <button
                        type={'button'}
                        className={
                            'c-booking-mask-popup__close-button react-datepicker__close-button'
                        }
                        onClick={() => closeDialog()}
                    >
                        <span className="hidden">{t('Close')}</span>
                    </button>
                    <DatePicker
                        onChange={(d: [Date | null, Date | null]) => {
                            setDates([d[0] || undefined, d[1] || undefined]);
                        }}
                        startDate={dates[0]}
                        endDate={dates[1]}
                        selectsRange
                        renderDayContents={renderDayContents}
                        onMonthChange={(date) => {
                            setSelectedMonth(date.getMonth() + 1);
                            setSelectedYear(date.getFullYear());
                        }}
                        minDate={new Date()}
                        shouldCloseOnSelect={responsive !== 'mobile'}
                        inline
                        popperPlacement={'bottom-start'}
                        placeholderText={t('Kies een datum')}
                        locale={i18n.language}
                        dateFormat={'d MMMM'}
                        chooseDayAriaLabelPrefix={t('Kies')}
                        previousMonthAriaLabel={t('Vorige maand')}
                        nextMonthAriaLabel={t('Volgende maand')}
                        monthAriaLabelPrefix={t('Maand')}
                        weekAriaLabelPrefix={t('Week')}
                        disabledDayAriaLabelPrefix={t('Niet beschikbaar')}
                    >
                        <div className={'react-datepicker__extra-info'}>
                            {bookingEngineId && (
                                <p
                                    className={
                                        'react-datepicker__extra-info-text'
                                    }
                                >
                                    *
                                    {t(
                                        'Getoonde prijzen zijn indicatief voor één overnachting. Na het kiezen van de data ziet u het totaalbedrag.'
                                    )}
                                </p>
                            )}
                            <div
                                className={
                                    'react-datepicker__select-button-container'
                                }
                            >
                                <button
                                    className={'c-button c-button--primary'}
                                    type="button"
                                    onClick={() => closeDialog()}
                                >
                                    {t('Selecteer')}
                                </button>
                            </div>
                        </div>
                    </DatePicker>
                </div>
            </dialog>
        </>
    );
}
