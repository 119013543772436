import * as Sentry from '@sentry/react';

declare global {
    interface Window {
        sentryDSN: string;
    }
}

export default function sentryConfig() {
    if (PRODUCTION && window.sentryDSN) {
        Sentry.init({
            dsn: window.sentryDSN,
            release: 'bilderberg-nl@' + APPLICATION_TAG,
            integrations: [Sentry.browserTracingIntegration()],
            tracesSampleRate: 1.0,
            normalizeDepth: 10,
            beforeBreadcrumb(breadcrumb) {
                if (breadcrumb.category === 'console') {
                    return null;
                }
                return breadcrumb;
            },
        });
    }
}
